html {
  font-size: 13px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 17px;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'OpenSans', sans-serif;
}

ol,
ul,
menu {
  padding: revert !important;
}

ul {
  list-style: disc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 30px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191919;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #191919;
  border-radius: 30px;
}
/* select {
  -webkit-appearance: none !important;
} */
.customSelect::after {
  width: 10px;
  height: 10px;
  display: block;
  right: 25px;
  top: 20px;
  z-index: 9999;
  border: 2px solid #757575;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
  position: absolute;
  content: '';
}
.download-accord button {
  border: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-accord span {
  display: none;
}
.download-accord .acc-title {
  flex: 1;
  text-align: left;
}
.ReactModal__Overlay--after-open {
  z-index: 999;
}

.mission-input input {
  width: 100%;
  border-radius: 5px;
  padding: 10px 19px;
}
.datePickerandtime-row .react-datepicker {
  border: 0px;
}
.datePickerandtime-row .react-datepicker__header {
  /* background-color: #7eb0b2; */
  border-bottom: 0px;
  padding: 5px 0;
}
.datePickerandtime-row .react-datepicker__current-month {
  font-size: 16px;
}
.datePickerandtime-row .react-datepicker__day,
.datePickerandtime-row .react-datepicker__day-name {
  width: 2rem;
  margin: 5px 0.166rem !important;
  /* color: #fff; */

  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 32px;
}
.react-datepicker__day-names .react-datepicker__day-name {
  color: #000;
  font-weight: 500;
  letter-spacing: 0.025rem;
}
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  /* background-color: #fff !important; */
  /* color: #7cb2b5 !important; */
  border-radius: 100% !important;
  height: 32px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.react-datepicker__day:hover {
  /* background-color: #fff !important; */
  /* color: #7cb2b5 !important; */
  border-radius: 100% !important;
}
.react-datepicker__day--disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.datePickerandtime-row .react-datepicker__navigation-icon::before {
  width: 12px;
  height: 12px;
  border-color: #000;
}
.datePickerandtime-row .react-datepicker__navigation:hover *::before {
  border-color: #000;
}
.datePickerandtime-row .react-datepicker__month {
  font-size: 14px;
  padding: 5px 20px 15px;
  margin: 0;
  /* background-color: #7eb0b2; */
}
.timeslot-list-block .timeslot-list {
  width: 100% !important;
  column-count: 4 !important;
}
.timeslot-list-block .timeslot {
  /* background-color: #E9E9E9; */
  border-radius: 10px;
  text-align: center;
  padding: 6px;
  border: 1px solid #7a7585;
  cursor: pointer;
  column-count: 10;
  max-width: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.clicked {
  background: #e5efef;
  color: #006367;
  border-radius: 10px;
  border-color: #b1cfd1 !important;
}
.makeBooking,
.timeslot-list-block .timeslot:hover {
  cursor: pointer;
  background: #006367;
  border-radius: 10px;
  color: #fff;
}
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #fff !important;
  color: #fff;
}

.mini-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.mini-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.mini-scrollbar::-webkit-scrollbar-thumb {
  background: #515151;
  border-radius: 24px;
}

.mini-scrollbar.horizontal::-webkit-scrollbar {
  height: 4px;
}

.mini-scrollbar.thick::-webkit-scrollbar {
  height: 12px;
}

/* class for no sidebar */
.no-sidebar::-webkit-scrollbar {
  height: 0;
  width: 0;
}
