@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/Open_Sans/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'wskf';
  src: url('./assets/fonts/WorldSkillsFR.otf');
}

@font-face {
  font-family: 'euclid-flex';
  src: url('./assets/fonts/EuclidFlex/EuclidFlex-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'euclid-flex';
  src: url('./assets/fonts/EuclidFlex/EuclidFlex-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Quick-Sand';
  src: url('./assets/fonts/Quicksand/static/Quicksand-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Quick-Sand';
  src: url('./assets/fonts/Quicksand/static/Quicksand-Bold.ttf');
  font-weight: 500;
  font-style: normal;
}
/**
*  DIAGONAL SECTIONS
**/
:root {
  --diagonal-section-offset: 30px;
}

@media (max-width: 768px) {
  :root {
    --diagonal-section-offset: 20px;
  }
}

@supports (-webkit-clip-path: inset(50%)) or (clip-path: inset(50%)) {
  .diagonal-section,
  .diagonal-section-top.diagonal-section-bottom {
    padding-top: calc(var(--diagonal-section-offset) / 2);
    padding-bottom: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(
      0% 0%,
      100% var(--diagonal-section-offset),
      100% 100%,
      0% calc(100% - var(--diagonal-section-offset))
    );
  }

  .diagonal-section--flip-x {
    clip-path: polygon(
      0% var(--diagonal-section-offset),
      100% 0%,
      100% calc(100% - var(--diagonal-section-offset)),
      0% 100%
    );
  }

  .header-logo {
    clip-path: polygon(0 0, 100% 0, 35% 32%, 0 100%);
  }

  .header-logo2 {
    clip-path: polygon(100% 0, 0 100%, 0 100%, 0 0);
  }

  .question_diagonal_one {
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }

  .question_diagonal_two {
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 100%);
  }

  .question_diagonal_three {
    clip-path: polygon(40% 60%, 30% 100%, 0 100%, 0 60%);
  }

  .question_diagonal_four {
    clip-path: polygon(100% 0, 100% 30%, 0 100%, 0 90%);
  }

  .side-nav-logo {
    clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
  }

  .side-logo-dk-1 {
    clip-path: polygon(100% 0, 80% 100%, 0 100%, 0 0);
  }

  .side-logo-dk-2 {
    clip-path: polygon(0 0, 85% 0, 0 100%, 0 0);
  }

  .side-logo-dk-3 {
    clip-path: polygon(0 0, 90% 100%, 0 100%, 0 0);
  }

  .diagonal-section-top {
    padding-top: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% 0%, 100% var(--diagonal-section-offset), 100% 100%, 0% 100%);
  }

  .diagonal-section-top--flip-x {
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% 100%, 0% 100%);
  }

  .diagonal-section-top--flip-x.diagonal-section-bottom {
    clip-path: polygon(
      0% var(--diagonal-section-offset),
      100% 0%,
      100% 100%,
      0% calc(100% - var(--diagonal-section-offset))
    );
  }

  .diagonal-section-bottom {
    padding-bottom: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - var(--diagonal-section-offset)));
  }

  .diagonal-section-bottom--flip-x {
    padding-bottom: calc(var(--diagonal-section-offset) / 1);
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - var(--diagonal-section-offset)), 0% 100%);
  }

  .diagonal-section-bottom--flip-x.diagonal-section-top {
    clip-path: polygon(
      0% 0%,
      100% var(--diagonal-section-offset),
      100% calc(100% - var(--diagonal-section-offset)),
      0% 100%
    );
  }

  .diagonal-section-top--flip-x.diagonal-section-bottom--flip-x {
    clip-path: polygon(
      0% var(--diagonal-section-offset),
      100% 0%,
      100% calc(100% - var(--diagonal-section-offset)),
      0% 100%
    );
  }

  .diagonal-section-top.diagonal-section-top--left {
    padding-top: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% 100%, 0 0% 100%);
  }
}
button {
  cursor: pointer;
}

.diagonal-section-top::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: var(--swissskillscolorschampions-5, #fce5e5) !important;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.07) inset !important;
  clip-path: inherit;
}

.slide-menu-container {
  transform: translate(0%);
  transition: transform 400ms ease-in-out;
}
.slide-menu-container.open {
  transform: translateX(100%);
  z-index: 100;
}

#input-enclose {
  float: right;
  font-size: 0;
  position: relative;
}

#input-enclose input {
  border: none;
  padding: 10px;
  font-size: 16px;
  width: 10px;
  margin-right: -1px;
  transition: all 0.3s;
  position: absolute;
  right: -8px;
  background: transparent;
  cursor: pointer;
  border-radius: 50px;
}

#input-enclose input:focus {
  width: 15em;
  outline: none;
  position: relative;
  background-color: #e5e7eb;
}

.ChSearchIcon {
  transition: all 0.3s;
}

#input-enclose input:focus + button {
  padding: 10px 0;
  position: absolute;
  right: 0;
}

#input-enclose input:focus + button svg {
  width: 1.3em;
  height: 1.3em;
}

.fr .bg-primary {
  @apply bg-blue-primary;
}
.fr .select-primary {
  @apply bg-gold-primary;
}
.fr .hover-primary:hover {
  @apply bg-gold-primary;
}
.fr .select-secondry {
  @apply bg-gold-secondary;
}
.fr .hover-secondry:hover {
  @apply bg-gold-secondary;
}
.fr .bg-cross-button {
  @apply bg-blue-primary;
}
.fr .list-a {
  list-style-type: lower-alpha;
}
.fr .plus-button {
  @apply bg-gold-primary;
}
.fr .border-button-primary {
  @apply border-blue-primary;
}
.fr .border-button-primary:hover {
  @apply border-gold-primary;
}
.fr .text-primary {
  @apply text-blue-primary;
}
.fr .blue-primary {
  @apply text-blue-primary;
}
.fr .red-primary {
  @apply text-cred-danger;
}
.fr .t-center {
  text-align: -webkit-center;
}

.en .bg-primary {
  @apply bg-blue-primary;
}
.en .select-primary {
  @apply bg-swiss-200;
}
.en .hover-primary:hover {
  @apply bg-swiss-200;
}
.en .select-secondry {
  @apply bg-swiss-50;
}
.en .hover-secondry:hover {
  @apply bg-swiss-50;
}
.en .bg-cross-button {
  @apply bg-blue-primary;
}
.en .list-a {
  list-style-type: lower-alpha;
}
.en .plus-button {
  @apply bg-gold-primary;
}
.en .border-button-primary {
  @apply border-blue-primary;
}
.en .text-primary {
  @apply text-blue-primary;
}
.en .red-primary {
  @apply text-cred-danger;
}
.en .blue-primary {
  @apply text-blue-primary;
}
.en .t-center {
  text-align: -webkit-center;
}

.ch,
.ch * {
  font-family: OpenSans;
}
.ch .bg-primary {
  @apply bg-swiss-300;
}
.ch .select-primary {
  @apply bg-swiss-200;
}
.ch .hover-primary:hover {
  @apply bg-swiss-200 border-swiss-200;
}
.ch .select-secondry {
  @apply bg-swiss-50;
}
.ch .hover-secondry:hover {
  @apply bg-swiss-50;
}
.ch .bg-cross-button {
  @apply bg-swiss-100;
}
.ch .list-a {
  list-style-type: lower-alpha;
}
.ch .plus-button {
  @apply bg-swiss-150;
}
.ch .bg-input {
  @apply bg-white;
}
.ch .bg-input-pop {
  @apply bg-cwhite-primary;
}
.ch .border-button-primary {
  @apply border-black;
}
.ch .text-primary {
  @apply text-black;
}
.ch .red-primary {
  @apply text-cred-danger;
}
.ch .blue-primary {
  @apply text-swiss-300;
}
.ch .t-center {
  text-align: -webkit-center;
}

.signup {
  font-family: OpenSans;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .signup .signupform .signupinwrap {
    width: 100%;
  }
}
.signup .signupform .signupinwrap .css-dy0iso-control,
.signup .signupform .signupinwrap .css-1qth7a8-control,
.signup .signupform .signupinwrap .css-1qth7a8-control:hover {
  background-color: transparent;
  padding: 10px;
}
.signup .signupform input[type='checkbox'] {
  position: absolute;
  cursor: pointer;
  width: 0px;
  height: 0px;
  -moz-appearance: initial;
  appearance: none !important;
  -webkit-appearance: none;
  margin-top: 5px;
}
.signup .signupform input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #666;
}
.signup .signupform input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 21px;
  height: 21px;
  background-color: #666;
  top: 0;
  left: 0;
}
.signup .signupform input[type='checkbox']:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border: solid #fff;
  border-width: 0 0px 0px 0;
  position: absolute;
  top: 0px;
  left: 50px;
}
.signup .signupform input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 8px;
  height: 16px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 0px;
  left: 7px;
}
@media (max-width: 767px) {
  .signup .signupform input[type='checkbox']:before {
    width: 25px !important;
    height: 25px !important;
    top: 0px;
  }
  .signup .signupform input[type='checkbox']:checked:after {
    top: 3px;
    left: 9px;
  }
}
.signup .signupform .ch-check-input label {
  width: calc(100% - 31px);
  margin-left: 30px;
}

.slide {
  display: flex;
  justify-content: center;
}

.carousel .control-dots {
  bottom: -17px;
  margin-top: 120px;
}
@media (max-width: 767px) {
  .carousel .control-dots {
    bottom: 7px !important;
    margin: 10px 0 0 !important;
  }
}

#quels-slider .carousel .control-dots {
  transform: translateY(10px);
}
#quels-slider .carousel .control-dots .dot {
  border: 2px solid #006367;
}
#quels-slider .carousel .control-dots .dot.selected {
  background-color: #006367;
}

.img-slide .img-desktop {
  @apply hidden md:inline-block w-full h-fit md:max-h-[600px] -mt-10 md:mt-0 md:object-contain;
}
.img-slide .img-mobile {
  @apply inline-block md:hidden lg:hidden w-full inline-block  object-contain !important;
}

.carousel .slide img.slide-icon {
  filter: invert(1);
  width: auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.auto-center {
  max-width: 70%;
  margin: 0 auto;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  align-items: center;
}

.ch-blur::after {
  content: '';
  background: linear-gradient(360deg, #fff 40.95%, rgba(255, 255, 255, 0) 98.11%);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 115px;
  left: 0;
  display: block;
}

.slider-control-bottomcenter {
  position: relative;
  top: 40px;
}

.slider-control-bottomcenter ul {
  list-style-type: none !important;
}

.paging-item button {
  border: 2px solid #006367 !important;
  margin: 20px 5px 0px 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.paging-item.active button {
  background-color: #006367 !important;
}

.paging-item button svg {
  display: none;
}

@media (max-width: 767px) {
  .slider-control-bottomcenter {
    display: none;
  }
}
@media (max-width: 768px) {
  .social-icons svg {
    width: 16px;
  }
}
#access-code-form input {
  @apply w-20 h-24 rounded text-5xl text-center leading-none text-gray-primary mr-2 border;
}

#access-code-form[data-theme='ch'] input {
  @apply border-swiss-300;
}

.sidebar-subdropdown {
  position: relative;
}
.sidebar-subdropdown::before {
  content: '';
  position: absolute;
  top: -5%;
  left: -1.85rem;
  width: 1px;
  height: 100%;
  background-color: #000000;
}
.sidebar-subdropdown:after {
  content: '';
  position: absolute;
  top: 53%;
  left: -1.85rem;
  height: 1px;
  width: 1.2rem;
  background-color: #000000;
}
.sidebar-subdropdown .selection-circle {
  position: absolute;
  top: 48%;
  left: -0.7rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #000000;
}
.sidebar-subdropdown:first-child:before {
  top: -17%;
  height: 120%;
}
.sidebar-subdropdown:first-child .selection-circle {
  top: 48%;
}
.sidebar-subdropdown:last-child:before {
  height: 60%;
}
.sidebar-subdropdown.sidebar-subdropdown-selected .selection-circle {
  background-color: #000000;
}
